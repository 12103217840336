<template>
 <div id="camera-modal">
        <video autoplay playsinline ref="video" class="camera-stream"></video>
        <canvas ref="cv" class="canvas"></canvas>
 
        <v-btn class="take-picture-button" fab @click="processImg">
          <v-icon color="primary darken-4">mdi-camera</v-icon>
        </v-btn>

         <div id="instruction" class="text-center natural--text  text-title-3 pb-5">
            กรุณาวางบัตรให้อยู่ในกรอบที่กำหนด
        </div>
         <div id="instruction2" class="text-center natural--text  text-title-3 pa-15">
           เลี่ยงที่แสงสะท้อน หรือที่มืดเกินไป
ควรวางบัตรราบกับพื้น
เพื่อให้อ่านตัวอักษรได้ชัดเจน
        </div> 
      <CameraLoadingCard :overlay="loading" />
      <v-dialog v-model="dialog" persistent>
        <CameraFailCard @onClose="dialog=false" @onSkip="$router.push({ name: 'info'})"/>
      </v-dialog>
</div>
</template>

<script>
// import CameraNavBar from "@/components/camera/CameraNavBar";
import CameraLoadingCard from "@/components/camera/CameraLoadingCard";
import CameraFailCard from '@/components/camera/CameraFailCard'
import { ocrMeddevice}  from "@/api"
import { mapFields } from 'vuex-map-fields';
// eslint-disable-next-line no-unused-vars
import adapter from 'webrtc-adapter'

export default {
  components: {
    // CameraNavBar,
    CameraLoadingCard,
    CameraFailCard
  },
  data() {
    return {
      overlay: true,
      mediaStream: null,
      switchCamera: false,
      snackmessage: "",
      snackbar: false,
      isChrome57or58: null,
      loading: false,
      dialog: false
    };
  },
  computed: {
    ...mapFields(['citizenObj'])
  },
  destroyed() {
    if(this.mediaStream){
    this.mediaStream.getTracks().forEach((track) => {
      track.stop();
    });
    }
  },
  mounted() {
    console.log(adapter.browserDetails.browser)
    let supports = navigator.mediaDevices.getSupportedConstraints();
    console.log(supports)
    let switchCamera = false;
    if (supports["facingMode"] === true) {
      switchCamera = true;
    }
    const constraints = {
      audio: false,
      video: {
        facingMode: switchCamera ? "environment" : "user",
      },
    };

    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((mediaStream) => {
          this.mediaStream = mediaStream;
          this.$refs.video.srcObject = mediaStream;
          this.$refs.video.play();
          this.$refs.video.addEventListener("loadedmetadata", () => {
            console.log("Screensize:" + screen.width + "/" + screen.height);
            this.$refs.cv.width = screen.width;
            this.$refs.cv.height = screen.height;
            this.$refs.video.height = screen.height;
            let ctx = this.$refs.cv.getContext("2d");
            ctx.fillStyle = "rgba(0,0,0,0.8)";
            ctx.fillRect(0, 0, screen.width, screen.height / 5);
            ctx.fillRect(
              0,
              (screen.height / 3) * 2,
              screen.width,
              screen.height
            );

          });
        })
        .catch((error) => {
         //const tempStream = await navigator.mediaDevices.getUserMedia({video:true})
          navigator.mediaDevices.enumerateDevices().then((devices)=> {
            let frontDeviceId
         let backDeviceId
          if (devices.length > 0) {
            /* defaults so all this will work on a desktop */
            frontDeviceId = devices[0].deviceId;
            backDeviceId = devices[0].deviceId;
          }
           /* look for front and back devices */
          devices.forEach (device => {
            if( device.kind === 'videoinput' ) {
              if( device.label && device.label.length > 0 ) {
                if( device.label.toLowerCase().indexOf( 'back' ) >= 0 ) 
                  backDeviceId = device.deviceId
                else if( device.label.toLowerCase().indexOf( 'front' ) >= 0 )
                  frontDeviceId = device.deviceId
              }
            }
          })
         console.log(error)
         console.log(frontDeviceId)
         const constraintsMobile = {
              video: true,
              deviceId: {exact: backDeviceId }
          }

           navigator.mediaDevices
        .getUserMedia(constraintsMobile)
        .then((mediaStream) => {
          this.mediaStream = mediaStream;
          this.$refs.video.srcObject = mediaStream;
          this.$refs.video.play();
          this.$refs.video.addEventListener("loadedmetadata", () => {
            console.log("Screensize:" + screen.width + "/" + screen.height);
            this.$refs.cv.width = screen.width;
            this.$refs.cv.height = screen.height;
            this.$refs.video.height = screen.height;
            let ctx = this.$refs.cv.getContext("2d");
            ctx.fillStyle = "rgba(0,0,0,0.8)";
            ctx.fillRect(0, 0, screen.width, screen.height / 5);
            ctx.fillRect(
              0,
              (screen.height / 3) * 2,
              screen.width,
              screen.height
            );

            //const height = screen.height;
            //const width = screen.width;

            // var img = new Image();
            // img.onload = function () {
            //   ctx.drawImage(
            //     img,
            //     0,
            //     0,
            //     img.naturalWidth,
            //     img.naturalHeight,
            //     0,
            //     height / 4,
            //     width,
            //     width * (img.naturalHeight / img.naturalWidth)
            //   );
            // };
            // img.src = require("@/assets/Group 2195.svg");
          });
        }).catch((error=>{
          console.log(error)
          this.$router.push({ name: 'info'})
        }))

         })
        });
    }else{
       this.$router.push({ name: 'info'})
    }
  },
  methods: {
   async processImg() {
      this.loading = true

       const canvas = document.createElement('canvas')
               canvas.width = this.$refs.video.videoWidth
               canvas.height = this.$refs.video.videoHeight
               //Choice full canvas
               //canvas.getContext('2d').drawImage(this.$refs.video, 0, 0 , canvas.width, canvas.height)
               
               //Choice 1 Fit screen crop 
               //canvas.getContext('2d').drawImage(this.$refs.video,((canvas.width-screen.width)/2-20),(canvas.height-screen.height)/2,screen.width,screen.height,0,0,screen.width,screen.height)

               //Choice 2 Center Crop
               //canvas.getContext('2d').drawImage(this.$refs.video,((canvas.width-screen.width)/2-20),((canvas.height-screen.height)/2)+(screen.height/4)+10,screen.width,screen.height/3,0,0,screen.width,(screen.height/3)) 
               // canvas.getContext('2d').drawImage(this.$refs.video,((canvas.width-screen.width)/2 ),(screen.height / 3) * 2,screen.width ,canvas.height/3,0,0,screen.width,(screen.height / 3) * 2)
              
              canvas.getContext('2d').drawImage(this.$refs.video,((canvas.width-screen.width)/2),(canvas.height)/5,screen.width -25,(screen.height / 3) ,0,0,screen.width -25,(screen.height / 3))
               var dataURL = canvas.toDataURL()
               dataURL.replace('data:image/png;base64,','')
               let base64ContentArray = dataURL.split(",")   
               let base64Data = base64ContentArray[1]
               console.log(base64Data)
               let imageBlob = await new Promise(resolve => canvas.toBlob(resolve, 'image/png'));
               let formData = new FormData();
               formData.append("image", imageBlob, "image.png");

               ocrMeddevice({modelId: '11d522c1-d4c3-42f9-ac00-1723a662d112', appKey: '7badCjWLfn0dsh7rK1N1_YUuc0f_YElQ'}, formData, message => {
                   // console.log(message.data.result)
                    if(message.data.result) {
                      if(message.data.result[0].message === 'Success') {
                        this.$emit('onSuccess',message.data.result[0].prediction)
                      }
                    }else {
                      this.$emit('onClose')
                    }
                   this.loading = false
               }, error => {
                   console.log(error)
               })
    }
  },
};
</script>

<style lang="scss" scoped>
#camera-modal {
        width: 100%;
        height: 100%;
        top: 20;
        left: 0;
        position: absolute;
        background-color: white;
        z-index: 1;
        video {
    height: 100%;
    z-index: 0;
  }
}
.canvas {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  max-height: 100%;
}
#instruction {
  position: absolute;
  margin: auto;
  width:100%;
  top: 70px;
  z-index: 0;
}
#instruction2{
  position: absolute;
  margin: auto;
  width: 100%;
  bottom: 50px;
  z-index: 0;
}
.take-picture-button {
  display: block;
  margin: auto;
  bottom: 80px;
}
#videoContainer {
  box-sizing: border-box;
  height: 100%;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  video {
    height: 100%;
    -ms-transform: translateX(-25%);
    -webkit-transform: translateX(-25%);
    transform: translateX(-25%);
    z-index: 0;
  }
}
</style>