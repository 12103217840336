<template>
<v-app>
      <v-system-bar></v-system-bar>
      <AppBarStep :title="form.arrResult[0].formTypeName"/>
  <v-main class="natural lighten-3">
    <!-- <v-container fluid class="natural lighten-3">
      <v-row>
        <v-col cols="4"><StepCardInActive :title="'ข้อมูลส่วนตัว'" /></v-col>
        <v-col cols="4"><StepCardActive :title="'ข้อมูลสุขภาพ'" /></v-col>
        <v-col cols="4"><StepCardInActive :title="'สำเร็จ'" /></v-col>
      </v-row>
    </v-container> -->
    <v-container fluid class="ma-0 pa-0">
      <v-row class="ma-0 pa-0">
        <v-col cols="12" class="ma-0 pa-0">
          <v-card elevation="0"  tile>
            <v-card-title>ข้อมูลสุขภาพ</v-card-title>
            <v-card-text>
                <v-form ref="formInfo" v-model="valid" lazy-validation>
              <TextField
                disable
                v-model="form.arrResult[0].id_number"
                v-mask="'#-####-#####-##-#'"
                :title="'บัตรประจำตัวประชาชน'"
                :rules="[(v) => !!v || 'กรุณากรอกข้อมูล', v => (v && v.length >= 17) || 'กรุณากรอกข้อมูลให้ครบถ้วน']"
              />
              <TextField
                disable
                :title="'ชื่อ-นามสกุล'"
                v-model="form.arrResult[0].name_th"
                 :rules="[(v) => !!v || 'กรุณากรอกข้อมูล']"
              />
               <TextArea v-model="form.arrResult[0].address_th" :title="'ที่อยู่'"
                 :rules="[(v) => !!v || 'กรุณากรอกข้อมูล']"
               />
                </v-form>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" class="ma-0 pl-0 pr-0">
          <v-card elevation="0" tile>
            <v-card-title
              ><v-row
                ><v-col cols="10">{{ this.form.arrResult[0].formTypeName }}</v-col
                ><v-col cols="1"></v-col></v-row
            ></v-card-title>
            <v-card-text>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-row dense>
                    <template   v-for="(item, index) in form.arrResult[0].schema.fields">
                  <v-col
                    :key="index"
                    cols="12"
                    v-if="item.visible"
                  >
                   <SelectMultiWidget
                     v-if="item.type === 'select' && visibleCondition(item) && item.multiple && item.visible"
                      v-bind="item"
                      v-model="item.value"
                    />

                    <!--.schema.fields -->
                    <SelectWidget
                      v-if="item.type === 'select' && visibleCondition(item) && !item.multiple && item.visible"
                      v-bind="item"
                      v-model="item.value"
                    />
                    <!-- <NumberWidget v-if="item.type==='number'" :question="item"/> -->
                    <TextWidget
                      class="pa-0 ma-0"
                      v-if="item.type === 'text' && visibleCondition(item) && item.visible"
                      v-bind="item"
                      v-model="item.value"
                    />

                      <ImageUploadWidget
                      class="pa-0 ma-0"
                      v-if="item.type === 'image' && visibleCondtion(item) && item.visible"
                      :title="item.title"
                      v-model="item.value"/>

                         <SectionWidget
                      v-if="item.type === 'section' && visibleCondtion(item) && item.visible"
                      :title="item.title"
                      v-model="item.value"
                    />
                    
                  </v-col>
                    </template>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" class="ma-0 pl-0 pr-0">
          <v-card tile elevation="0">
            <v-card-text>
              <v-row>
                <v-col cols="12" class="pt-2">
                  <v-btn
                    block
                    large
                    outlined
                    color="primary"
                    @click="confirmDraftDialog = true"
                    ><span class="text-title-2">บันทึกร่าง</span></v-btn
                  >
                </v-col>
                <v-col cols="12" class="pt-0">
                  <v-btn
                    block
                    large
                    color="primary text-title-2"
                    @click="validateData"
                    >ส่งข้อมูล</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="confirmDialog" width="327">
      <ConfirmCard @onClose="confirmDialog = false" @onConfirm="sendData()" />
    </v-dialog>
    <v-dialog v-model="confirmDraftDialog" width="327">
      <ConfirmCard
        @onClose="confirmDraftDialog = false"
        @onConfirm="saveDraft()"
      />
    </v-dialog>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-main>
</v-app>
</template>

<script>
//import StepCardActive from "@/components/form/StepCardActive";
//import StepCardInActive from "@/components/form/StepCardInactive";
import TextField from "@/components/form/TextField";
import ConfirmCard from "@/components/form/ConfirmCard";
import SelectWidget from "@/components/form/SelectWidget";
// import NumberWidget from "@/components/form/NumberWidget";
import TextWidget from "@/components/form/TextWidget";
// import TextArea from "@/components/form/TextArea";
import { postFormResult } from "@/api/";
import { mask } from "vue-the-mask";
import { mapFields } from "vuex-map-fields";
import AppBarStep from "@/components/form/AppBarStep";
import TextArea from "@/components/form/TextArea";
import SelectMultiWidget from "@/components/form/SelectMultiWidget";
import ImageUploadWidget from "@/components/form/ImageUploadWidget";

const required = (msg) => (v) => !!v || msg;

export default {
  components: {
    //StepCardActive,
   // StepCardInActive,
    TextField,
    ConfirmCard,
    SelectWidget,
    TextWidget,
    AppBarStep,
    TextArea,
    SelectMultiWidget,
    ImageUploadWidget
    //NumberWidget
    // TextArea,
  },
  computed: {
    ...mapFields(["citizenObj", "formOffline", "formDraft"]),
  },
  directives: { mask },
  data() {
    return {
      cid: null,
      confirmDialog: false,
      confirmDraftDialog: false,
      schema: null,
      loading: null,
      valid: false,
      role: null,
      form: null,
      form2: [
        {
          title: "เพศ",
          name: "gender",
          required: true,
          choices: ["ชาย", "หญิง"],
          type: "select",
        },
        {
          title: "น้ำหนัก",
          name: "weight",
          placeholder: "กิโลกรัม",
          required: true,
          type: "text",
          inputType: "number",
          value: 90,
        },
        {
          title: "ความดันโลหิตตัวบน",
          name: "sys",
          placeholder: "mmHg",
          required: true,
          type: "text",
          inputType: "number",
        },
        {
          title: "ความดันโลหิตตัวล่าง",
          name: "dia",
          placeholder: "mmHg",
          required: true,
          type: "text",
          inputType: "number",
        },
        {
          title: "อัตราการเต้นหัวใจ",
          name: "pulse",
          placeholder: "bpm",
          required: true,
          type: "text",
          inputType: "number",
        },
        {
          title: "วิธีตรวจน้ำตาล",
          name: "dtx",
          required: true,
          choices: ["DTX", "FBS"],
          type: "select",
        },
        {
          title: "การตรวจวัดน้ำตาล",
          name: "method",
          required: true,
          choices: ["โดยอดอาหาร", "โดยไม่อดอาหาร"],
          type: "select",
        },
        {
          title: "ผลตรวจวัดน้ำตาล (mg/dl)",
          name: "bg",
          placeholder: "mg/dl",
          required: true,
          type: "text",
          inputType: "number",
        },
        {
          title: "ประวัติการสูบบุหรี่",
          name: "smoke",
          required: true,
          choices: [
            "ไม่สูบบุหรี่",
            "นานๆครั้ง",
            "เป็นครั้งคราว",
            "สูบเป็นประจำ",
          ],
          type: "select",
        },
        {
          title: "ประวัติการดื่มแอลกอฮอล",
          name: "alh",
          required: true,
          choices: ["ไม่ดื่ม", "นานๆครั้ง", "เป็นครั้งคราว", "ดื่มเป็นประจำ"],
          type: "select",
        },
        {
          title: "ญาติสายตรงของท่านมีประวัติการเจ็บป่วย",
          name: "familyDesease",
          required: true,
          choices: ["ไม่มีโรค", "มีโรค", "ไม่ทราบ"],
          type: "select",
          value: "ไม่มีโรค",
        },
        {
          title: "โรคประจำตัวของญาติท่าน",
          name: "familyNCD",
          required: true,
          visibleIf: "{familyDesease}='มีโรค'",
          choices: ["เบาหวาน", "ความดัน"],
          type: "select",
        },
      ],
    };
  },
  methods: {
    buildSchema() {
      this.form.arrResult[0].schema.fields.map((obj) => {
        if (obj.required) {
          obj.rules = [required("กรุณากรอกข้อมูล")];
        }

        if (obj.visibleIf) {
          let property = obj.visibleIf.match(/\{(.*?)\}/);
          let val = obj.visibleIf.match(/'([^']+)'/);
          obj.conditionprop = property[1].trim();
          obj.conditionval = val[1].trim();
        }
        
      });
    },
    changeValue() {
      let data = this.form.arrResult[0].schema.fields.filter(
        (obj) => obj.name === "familyDesease"
      );
      if (data[0].value === "มีโรค") {
        data[0].value = "ไม่มีโรค";
      } else {
        data[0].value = "มีโรค";
      }
    },
    visibleCondition(item) {
      if (!item.visible) return false;
      if (!item.visibleIf) return true;
      let filterObj = this.form.arrResult[0].schema.fields.filter(
        (obj) => obj.name === item.conditionprop
      );
      if (!filterObj.length) return true;
      if (filterObj[0].value && (filterObj[0].value.title === item.conditionval)) {
        return true;
      } else {
        return false;
      }
    },
    buildModel() {
      let model = {};
      this.form.arrResult[0].schema.fields.map((obj) => {
        model[obj.name] = obj.value;
      });
      return model;
    },
     validateData() {
      if (this.$refs.form.validate() & this.$refs.formInfo.validate() & this.validateSelectWidget()) {
        this.confirmDialog = true;
      }else{
        this.$vuetify.goTo(500, 'number')
      }
    },
    validateSelectWidget() {
      let validate = true;
      for (let i = 0; i < this.form.arrResult[0].schema.fields.length; i++) {
        if (
          this.form.arrResult[0].schema.fields[i].type === "select" &&
          this.form.arrResult[0].schema.fields[i].required
        ) {
          if (!this.form.arrResult[0].schema.fields[i].value) {
            if(this.visibleCondition(this.form.arrResult[0].schema.fields[i])){
              this.form.arrResult[0].schema.fields[i].error = true;
              validate = false;
            }else{
              this.form.arrResult[0].schema.fields[i].error = false;
            }
          }else if (Array.isArray(this.form.arrResult[0].schema.fields[i].value) && !this.form.arrResult[0].schema.fields[i].value.length)
          {
              if(this.visibleCondition(this.form.arrResult[0].schema.fields[i])){
              this.form.arrResult[0].schema.fields[i].error = true;
              validate = false;
            }else{
              this.form.arrResult[0].schema.fields[i].error = false;
            }
          }else{
             this.form.arrResult[0].schema.fields[i].error = false;
          }
        }
      }
      return validate;
    },
    sendData() {
      //this.confirmDialog = false;

        this.role = this.$offlineStorage.get("role");
        this.form.arrResult[0].model = this.buildModel();
        let data = this.form;
        data.arrResult[0].id_number = data.arrResult[0].id_number.replace(/^(\+)|\D/g, '$1')
        console.log(data)
        if (this.isOnline) {
          this.loading = true;
          postFormResult(
            { data },
            (message) => {
              if (message.data.code === 1) {
                //remove from draft
                let draft = this.$offlineStorage.get("draft");
                for (let i = 0; i < draft.length; i++) {
                  if (
                    draft[i][1].arrResult[0].formResultId ===
                    this.form.arrResult[0].formResultId
                  ) {
                    draft.splice(i, 1);
                  }
                }
                this.$offlineStorage.set("draft", draft);
                this.$router.replace("/form/complete");
              }
              this.loading = false;
            },
            (error) => {
              this.loading = false;
              console.log(error);
            }
          );
        } else {
          // Save data to localstorage
          let formOffline = this.$offlineStorage.get("formOffline");
          if (formOffline) {
            formOffline.push([this.role.uuid.mobile, data]);
          } else {
            formOffline = [];
            formOffline.push([this.role.uuid.mobile, data]);
          }

          //remove from draft
          let draft = this.$offlineStorage.get("draft");
                for (let i = 0; i < draft.length; i++) {
                  if (
                    draft[i][1].arrResult[0].formResultId ===
                    this.form.arrResult[0].formResultId
                  ) {
                    draft.splice(i, 1);
                  }
                }
          this.$offlineStorage.set("draft", draft);
          this.$offlineStorage.set("formOffline", formOffline);
          this.$router.replace("/form/complete");
        }
      
    },
    saveDraft() {
      this.confirmDraftDialog = false;
      this.role = this.$offlineStorage.get("role");
      this.form.arrResult[0].model = this.buildModel();
      let data = this.form;
   
      // Save data to localstorage
      
        let draft = this.$offlineStorage.get("draft");
                for (let i = 0; i < draft.length; i++) {
                  if (
                    draft[i][1].arrResult[0].formResultId ===
                    this.form.arrResult[0].formResultId
                  ) {
                    draft[i][1] = data
                  }
        }
    
      this.$offlineStorage.set("draft", draft);
      this.$router.replace("/form/complete");
    },
    fetchData() {
      this.loading = true;
      this.form = this.$offlineStorage.get("currentForm");
      this.loading = false;
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
  created() {
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData();
        this.buildSchema();
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    );
  },
};
</script>

<style lang="scss" scoped>
.v-btn {
  border-radius: 6px;
}
</style>