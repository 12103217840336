<template>
    <div>
      <label class="text-title-3 primary--text text--darken-4"> {{title}} </label>
        <v-img v-if="value" :src="value" width="100%" contain/>
         <v-file-input
         outlined
         large 
         color="primary"
         class="ma-0 pa-0"
         :rules="rules"
         accept="image/png, image/jpeg, image/bmp"
         placeholder="แตะที่นี่เพื่อถ่ายภาพ"
         prepend-icon="mdi-camera"
         @change="fileChange"
  ></v-file-input>
    </div>
    <!-- </label> -->
</template>

<script>
import Reduce from "image-blob-reduce";

export default {
 model: {
    prop: 'value',
    event: 'change'
  },
 props: {
   title: {
      type: String
   },
   value: {
      type: String
   }
 },
 data () {
  return {
    rules:  [value => !value || value.size < 15000000 || 'file size should be less than 15  MB!'],
  }
 },
 methods: {
     fileChange (file) {
        if (file) {
            const rec = new Reduce()
            rec.toBlob(file, { max: 500 }).then(blob => { 
                var reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onload = e => {
                 this.$emit('change', e.target.result)
                }
            }
            )
        }
     }
 },
}
</script>

<style lang="scss" scoped>

// .v-btn[data-v-6350f88b] {
//     border-radius: 10px !important;
// }
//  .v-btn--outlined {
//     border: 3px solid currentColor !important;
// }
</style>