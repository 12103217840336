<template>
<div class="pt-3 pb-3">
  <label class="text-title-3 primary--text text--darken-4">
    {{ title }} <span v-if="error" class="error--text text-title-4"> *กรุณาเลือกคำตอบ</span></label>
    <v-row>
      <v-col v-for="(item, index) in items" :key="index" cols="12">
        <ButtonWidget :title="item.title" :index="index" :active.sync="item.active" @togle="buildValue"/>
      </v-col>
    </v-row>
</div>
</template>

<script>
import ButtonWidget from "@/components/form/ButtonToggleWidget";

export default {
  model: {
    prop: 'value',
    event: 'change'
  },
  components: {
    ButtonWidget,
  },
  props: ["title","value","error"],
  data() {
    return {
      items: [],

    }
  },
  mounted () {
    if(this.$attrs.choices){
      for(let i = 0 ; i< this.$attrs.choices.length ; i++){
        if(this.value){
          let active = false
          for(let j=0 ; j< this.value.length; j++){
            if(this.value[j].value===this.$attrs.choices[i].value){
              this.items.push({title:this.$attrs.choices[i].text,active: true, value: this.$attrs.choices[i].value })
              active = true
              }
          }
          if(!active){
            this.items.push({title:this.$attrs.choices[i].text,active: false , value: this.$attrs.choices[i].value})
          }
        }else{
           this.items.push({title:this.$attrs.choices[i].text,active: false ,  value: this.$attrs.choices[i].value })
        }
      }
    }
  },
  methods: {
    buildValue(){
    let data = []
    for(let i=0 ; i< this.items.length;i++){
          if(this.items[i].active){
           data.push({title: this.items[i].title, value: this.items[i].value} )
          }
      }
      //console.log(data)
    this.$emit('change',data)
    },
    togle(val) {
      for(let i=0 ; i< this.items.length;i++){
          if(val!==i){
           this.items[i].active =false
          }else{
            this.$emit('change',this.items[i])
          }
      }
    }
  },
  computed: {
    inputListeners: function () {
      var vm = this;
      // `Object.assign` merges objects together to form a new object
      return Object.assign(
        {},
        // We add all the listeners from the parent
        this.$listeners,
        // Then we can add custom listeners or override the
        // behavior of some listeners.
        {
          // This ensures that the component works with v-model
          input: function (event) {
            vm.$emit("input", event);
          },
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn {
  border-radius: 6px;
}
 .v-btn--outlined {
    border: 2px solid currentColor !important;
  }
  ::v-deep .v-btn__content { width: 100%; white-space: normal; }
</style>